<template>
	<div class="form-group" v-if="loaded">
		<div class="data-group">
			<div class="title">{{ $t('salary.personal_data.title') }}</div>
		</div>
		<div class="form">
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.cnp') }}*</label>
                    <div v-if="validator.cnp.$error && validator.cnp.$model == ''" class="error-msg">{{ $t('validator.required') }}</div>
                    <div v-if="!validator.cnp.minLength" class="error-msg">{{ $t('validator.required_cnp')}}</div>
					<div v-if="validator.cnp.$error && validator.cnp.minLength && !validator.cnp.validCNP" class="error-msg">{{ $t('validator.required_cnp_validation') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: validator.cnp.$error}">
					<div class="icon-left"><icon-cnp /></div>
					<div class="icon-right" v-if="personal_data.cnp != ''" @click.stop="personal_data.cnp = ''"><icon-close class="icon-clear" /></div>
					<input :maxlength="13" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" onkeydown="if(event.key==='.' || event.key==='-'){event.preventDefault();}" type="number" :placeholder="$t('salary.personal_data.cnp-ph')" class="input-text" v-model="personal_data.cnp">
				</div>
			</div>
			<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.citizenship') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-citizenship /></div>
					<div class="icon-right" v-if="personal_data.selectedCitizenship != ''" @click.stop="personal_data.selectedCitizenship = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="personal_data.selectedCitizenship"
						v-bind:class="{populate: personal_data.selectedCitizenship != ''}"
						class="select-tags-tbf"
						:options="optionsCountries"
						:allow-empty="true"
						:show-labels="false"
						track-by="id"
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.personal_data.citizenship-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="delimeter-form"></div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.country') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-globe /></div>
					<div class="icon-right" v-if="personal_data.selectedCountry != ''" @click.stop="personal_data.selectedCountry = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="personal_data.selectedCountry"
						v-bind:class="{populate: personal_data.selectedCountry != ''}"
						class="select-tags-tbf"
						:options="optionsCountries"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.personal_data.country-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div v-if="personal_data.selectedCountry && personal_data.selectedCountry.name === 'Romania'" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.county') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-county /></div>
					<div class="icon-right" v-if="personal_data.selectedCounty != ''" @click.stop="personal_data.selectedCounty = '', personal_data.selectedTown = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="personal_data.selectedCounty"
						v-bind:class="{populate: personal_data.selectedCounty != ''}"
						class="select-tags-tbf"
						:options="optionsCounties"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
                        @input="getTowns(personal_data.selectedCounty.id)"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.personal_data.county-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div v-else class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.county') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-county /></div>
					<div class="icon-right" v-if="personal_data.county != ''" @click.stop="personal_data.county = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('salary.personal_data.county-ph')" class="input-text" v-model="personal_data.county">
				</div>
			</div>
            <div v-if="personal_data.selectedCountry && personal_data.selectedCountry.name === 'Romania'" class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.town') }}</label>
				</div>
				<div class="input-box" v-bind:class="{'bg-gray': !personal_data.selectedCounty}">
					<div class="icon-left"><icon-city /></div>
					<div class="icon-right" v-if="personal_data.selectedTown != ''" @click.stop="personal_data.selectedTown = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="personal_data.selectedTown"
						v-bind:class="{populate: personal_data.selectedTown != ''}"
						class="select-tags-tbf"
						:options="optionsTowns"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
                        :disabled="!personal_data.selectedCounty"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.personal_data.town-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div v-else class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.town') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-city /></div>
					<div class="icon-right" v-if="personal_data.town != ''" @click.stop="personal_data.town = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('salary.personal_data.town-input-ph')" class="input-text" v-model="personal_data.town">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.street_and_number') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-street /></div>
					<div class="icon-right" v-if="personal_data.street_and_number != ''" @click.stop="personal_data.street_and_number = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('salary.personal_data.street_and_number-ph')" class="input-text" v-model="personal_data.street_and_number">
				</div>
			</div>
            <div class="delimeter-form"></div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.user_document_type') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-citizenship /></div>
					<div class="icon-right" v-if="personal_data.selectDocumentType != ''" @click.stop="personal_data.selectDocumentType = ''"><icon-close class="icon-clear" /></div>
					<multiselect 
						v-model="personal_data.selectDocumentType"
						v-bind:class="{populate: personal_data.selectDocumentType != ''}"
						class="select-tags-tbf"
						:options="optionsDocumentType"
						:allow-empty="true"
						:show-labels="false"
						track-by="id" 
						label="name"
						>
						<template slot="placeholder" slot-scope="props">
							<span class="text">
								{{ $t('salary.personal_data.user_document_type-ph') }}
							</span>
						</template>
						<template slot="noResult">{{ $t('create-user.no-results') }}</template>
						<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
					</multiselect>
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.series') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-cnp /></div>
					<div class="icon-right" v-if="personal_data.series != ''" @click.stop="personal_data.series = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('salary.personal_data.series-ph')" class="input-text" v-model="personal_data.series">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.number') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-cnp /></div>
					<div class="icon-right" v-if="personal_data.number != ''" @click.stop="personal_data.number = ''"><icon-close class="icon-clear" /></div>
					<input type="number" :placeholder="$t('salary.personal_data.number-ph')" class="input-text" v-model="personal_data.number">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.issued_by') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-cnp /></div>
					<div class="icon-right" v-if="personal_data.issued_by != ''" @click.stop="personal_data.issued_by = ''"><icon-close class="icon-clear" /></div>
					<input type="text" :placeholder="$t('salary.personal_data.issued_by-ph')" class="input-text" v-model="personal_data.issued_by">
				</div>
			</div>
            <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
				<div class="label-header">
					<label class="label">{{ $t('salary.personal_data.issue_date') }}</label>
				</div>
				<div class="input-box">
					<div class="icon-left"><icon-calendar /></div>
					<div class="icon-right" v-if="Object.keys(personal_data.issue_date).length" @click.stop="personal_data.issue_date = {}"><icon-close class="icon-clear" /></div>
					<FunctionalCalendar
						ref="CalendarDateOfEmployment"
						v-model="personal_data.issue_date"
						class="calendar-tbf"
						:configs="calendarConfigs"
					>
					<template v-slot:datePickerInput="props">
						<input
						class="vfc-single-input custom-input-picker"
						type="text"
						:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
						v-if="Object.keys(personal_data.issue_date).length"/>
						<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('salary.personal_data.issue_date-ph') }}</div>
					</template>
					</FunctionalCalendar>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import IconMobile from '../../../../Icons/Mobile'
	import IconCnp from '../../../../Icons/Cnp'
	import IconCitizenship from '../../../../Icons/Citizenship'
	import IconCounty from '../../../../Icons/County'
	import IconCity from '../../../../Icons/City'
	import IconStreet from '../../../../Icons/Street'
	import IconGlobe from '../../../../Icons/Globe'
	import IconCalendar from '../../../../Icons/Calendar'
	import IconClose from '../../../../Icons/Close'

	export default {
		components: {
			IconMobile,
			IconCnp,
			IconCitizenship,
			IconCounty,
			IconCity,
			IconStreet,
			IconGlobe,
			IconCalendar,
			IconClose
		},
		props: {
			personal_data: Object,
			validator: Object,
			optionsCountries: Array,
            optionsCounties: Array,
            optionsTowns: Array,
            optionsDocumentType: Array,
            error_cnp: Boolean
		},
		data() {
			return {
				loaded: false,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
			}
		},
		async mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
        methods: {
            getTowns(county_id){
                this.$root.$emit('countySelect', county_id)
            }
        }
	}
</script>